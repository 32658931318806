export const getSeverity = (status) => {
    let severity;
    switch (status) {
        case 'closed win':
            severity = 'success';
            break;
        case 'ditolak':
        case 'closed lost':
            severity = 'danger';
            break;
        case 'siap closed win':
            severity = 'primary';
            break;
        default:
            severity = 'warning';
    }
    return severity;
};
