import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/sales/pipeline',
        component: () => import('@/layout/SalesPipelineLayout.vue'),
        redirect: { name: 'AllPipelineView' },
        children: [
            {
                path: 'semua',
                name: 'AllPipelineView',
                component: () => import('@/views/PipelineView.vue'),
            },
            {
                path: 'sedang-berjalan',
                name: 'OnGoingPipelineView',
                component: () => import('@/views/PipelineView.vue'),
            },
            {
                path: 'closed-win',
                name: 'ClosedWinPipelineView',
                component: () => import('@/views/PipelineView.vue'),
            },
            {
                path: 'closed-lost',
                name: 'ClosedLostPipelineView',
                component: () => import('@/views/PipelineView.vue'),
            },
            {
                path: 'buat',
                name: 'CreatePipelineView',
                component: () => import('@/views/CreatePipelineView.vue'),
            },
            {
                path: 'detail',
                name: 'PipelineDetailView',
                component: () => import('@/views/PipelineDetailView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
